import {
  assign,
  cloneDeep,
  each,
  isArrayLikeObject,
  isObject,
  last,
  map,
  mapValues,
  mean,
  omit,
  size,
  startsWith,
  sum,
  merge,
  pickBy,
} from 'lodash';

import { protocolName } from '../config/dev-config';
import { selectedSettings } from '../config/firebase-config';
import { slackClientId } from '../config/slack-client-id';
import { zoomClientId } from '../config/zoom-client-id';
import { OrgId, SessionId, UserId } from '../models/db/vo.interface';
import { ProtocolPaths } from '../models/misc.interface';

import { encodeString } from './string-utils';

type LoginOAuthState = {
  isDesktopApp: boolean;
};

export const parseQueryParams = (url: any) => {
  const queryParams: any = {};
  const splat = url.split('#')[0].split('?');
  if (splat.length < 2) return queryParams;
  splat[1].split('&').forEach((pair: any) => {
    if (pair === '') return;
    const parts = pair.split('=');
    queryParams[parts[0]] = parts[1] && decodeURIComponent(parts[1].replace(/\+/g, ' '));
  });
  return queryParams;
};

export const parseProcessArgs = (argv: any) => {
  const parsedOptions = {};
  argv.forEach((opt: any) => {
    const [key, val] = opt.split('=');
    const cleanKey = key.replace(/--/g, '');
    parsedOptions[cleanKey] = val;
  });
  return parsedOptions;
};

export const urlFriendlyString = (string: string) =>
  string
    .replace(/ /g, '-') // convert space to dash
    .replace(/[,;:!?.'"()[{}@*/\]\\&#%`^+<=>|$]/g, ''); // strip all bad chars

export const getDownloadUrl = (platform: any, version: any) =>
  `https://storage.cloud.google.com/iteleport-prod.appspot.com/desktop-app/${platform}/${version}/${
    platform === 'darwin' ? 'Pop.zip' : 'Pop-Setup.exe'
  }`;

export function flattenObject(origObj: any, separator = '.', parentKey: any = undefined) {
  const obj = cloneDeep(origObj);
  // console.log('fn: ', parentKey, JSON.stringify(obj, null, 2));
  // map(obj, (val, key) => console.log(`testing ${key}: ${isArrayLikeObject(val) || isObject(val)}`));
  const innerObjectValues = mapValues(obj, (val, key): any =>
    isArrayLikeObject(val) || isObject(val)
      ? flattenObject(val, separator, `${parentKey ? `${parentKey}${separator}` : ''}${key}`)
      : val,
  );
  const expandedValues: any = {};
  each(innerObjectValues, (val, key) => {
    if (!isObject(val) && !isArrayLikeObject(val)) return;
    map(val, (innerVal, innerKey) => assign(expandedValues, { [`${key}${separator}${innerKey}`]: innerVal }));
  });
  const flattenedObject = merge(
    pickBy(innerObjectValues, (val) => !isObject(val) && !isArrayLikeObject(val)),
    expandedValues,
  );
  return flattenedObject;
}

export function cloneableObject(obj: any): any {
  const blocklistKeyNames = [
    'volume', // is a VolumeMeter object which contains AudioContext values which can't be cloned
    'track', // is a MediaStreamTrack object which can't be cloned
  ];
  if (isObject(obj)) return mapValues(omit(obj, blocklistKeyNames), cloneableObject);
  return obj;
}

export function getSlackOauthUrl(userId: UserId, slackTeamId?: string) {
  return [
    'https://slack.com/oauth/v2/authorize',
    '?',
    [
      `client_id=${slackClientId}`,
      `state=${encodeString(JSON.stringify({ userId, ts: Date.now() }))}`,
      [
        'scope=',
        [
          'commands',
          'team:read',
          'users:read',
          'users:read.email',
          'links:read',
          'links:write',
          'calls:write',
          'calls:read',
        ].join(','),
      ].join(''),
      ['user_scope=', ['im:write', 'chat:write', 'calls:write'].join(',')].join(''),
      `redirect_uri=${encodeURIComponent(
        `${selectedSettings.firebaseCloudFunctionUrlOrigin}/webhook/slack/handleOauth`,
      )}`,
      ...(slackTeamId ? [`team=${slackTeamId}`] : []),
    ].join('&'),
  ].join('');
}

export function getGoogleOauthUrl(state?: LoginOAuthState) {
  return [
    selectedSettings.googleClientId.authCodeUrl,
    '?',
    [
      `client_id=${selectedSettings.googleClientId.webClientId}`,
      `state=${encodeString(
        JSON.stringify({ ...(state ?? {}), destinationOrigin: window.location.origin, ts: Date.now() }),
      )}`,
      `redirect_uri=${encodeURIComponent(
        `${selectedSettings.firebaseCloudFunctionUrlOrigin}/api/handleGoogleLoginRedirect`,
      )}`,
      'response_type=code',
      `scope=${encodeURIComponent('profile email')}`,
      'prompt=select_account',
    ].join('&'),
  ].join('');
}

export function getProtocolUrlToJoinRoom(
  roomId: string,
  { shouldSkipPreviewPage }: { shouldSkipPreviewPage?: boolean } = {},
) {
  const joinProtocolPath: ProtocolPaths = 'join';
  return `${protocolName}://${joinProtocolPath}?${queryParamsToUrlFriendlyString({
    roomId,
    ...(shouldSkipPreviewPage && { shouldSkipPreviewPage }),
  })}`;
}

export function prettifyRoomId(roomId: string) {
  if (!roomId) return '';
  if (!isValid9DigitRoomId(roomId)) {
    const roomIdWithoutUrlSplat = roomId.split('/j/');
    if (roomIdWithoutUrlSplat.length === 1) return roomId;
    return roomIdWithoutUrlSplat[1];
  }
  const unprettifiedRoomId = unprettifyRoomId(roomId);
  if (unprettifiedRoomId.length <= 3) return unprettifiedRoomId;
  if (unprettifiedRoomId.length <= 6) return unprettifiedRoomId.replace(/([0-9]{3})([0-9]*)/, '$1-$2');
  return unprettifiedRoomId.replace(/([0-9]{3})([0-9]{3})([0-9]{1,3}).*/, '$1-$2-$3');
}

export function unprettifyRoomId(roomId: string) {
  if (!roomId) return '';
  if (!isValid9DigitRoomId(roomId)) return roomId;
  const unprettifiedRoomId = String(roomId.replace(/[^0-9]/g, ''));
  return unprettifiedRoomId;
}

export const isValid9DigitRoomId = (maybeRoomId: string) =>
  maybeRoomId?.trim().length < 12 && String(maybeRoomId).replace(/[^0-9]/g, '').length === 9;

export function getJoinUrlForRoomId(roomId: string) {
  return `${selectedSettings.origin}/j/${prettifyRoomId(roomId)}`;
}

export function getJoinUrlForSessionId(sessionId: SessionId) {
  return `${selectedSettings.origin}/j/${sessionId}`;
}

function safeParseUrl(urlString: string) {
  let url: URL;
  try {
    url = new URL(urlString);
  } catch (error) {
    console.error(`getRoomIdFromJoinUrl: could not parse URL from ${urlString}`);
    return undefined;
  }
  return url;
}

export function getSessionIdFromJoinUrl(urlString: string) {
  const url = safeParseUrl(urlString);
  if (!url) return undefined;
  if (!startsWith(url.pathname, '/j/')) return undefined;
  const splat = url.pathname.split('/j/');
  if (!size(splat)) return undefined;
  const sessionId = last(splat)?.replace(/\//g, '');
  if (sessionId) return sessionId as SessionId;
}

export function stdDev(array: number[]) {
  const avg = mean(array);
  return Math.sqrt(sum(map(array, (i) => Math.pow(i - avg, 2))) / size(array));
}

export const isTrue = 1 + 1 === 2;

export const isEmailValid = (email: string) =>
  !!email &&
  email.indexOf('@') > 0 &&
  email.lastIndexOf('.') > email.indexOf('@') + 1 &&
  last(email.split('.'))!.length > 1;

export const queryParamsToUrlFriendlyString = (args: any) =>
  map(args, (val, key) => `${key}=${val}`).join('&');

export async function sleep(sleepMs = 0) {
  return new Promise((r) => setTimeout(r, sleepMs));
}

export function getGcalOauthCodeUrl(userId: UserId, orgId: OrgId) {
  return [
    selectedSettings.googleClientId.authCodeUrl,
    `?client_id=${selectedSettings.googleClientId.webClientId}`,
    `&state=${encodeString(JSON.stringify({ userId, orgId, ts: Date.now() }))}`,
    '&scope=https://www.googleapis.com/auth/calendar.events%20https://www.googleapis.com/auth/calendar.readonly%20openid%20email',
    `&redirect_uri=${encodeURIComponent(`${selectedSettings.origin}/handle-redirect/gcal-oauth`)}`,
    '&response_type=code',
    '&access_type=offline',
    '&prompt=consent',
  ].join('');
}

export function getZoomOauthCodeUrl(userId: UserId) {
  return [
    'https://zoom.us/oauth/authorize',
    '?response_type=code',
    `&client_id=${zoomClientId}`,
    `&state=${encodeString(JSON.stringify({ userId, ts: Date.now() }))}`,
    `&redirect_uri=${encodeURIComponent(`${selectedSettings.origin}/handle-redirect/zoom-oauth`)}`,
  ].join('');
}

// Branded types are a typescript trick to allow nominal typing among similar
// types.
//
// Usage:
//   type USD = UniqueType<number, 'USD'>
//   type EUR = UniqueType<number, 'EUR'>
//   const a: USD = 100;
//   const b: EUR = a; <-- error
export type UniqueType<K, T> = K & { __brand: T };

export type ParamsAfterFirst<T> = T extends (first: any, ...args: infer U) => any ? U : never;
export type FirstArgument<T> = T extends (first: infer U, ...args: any[]) => any ? U : never;
export type SecondArgument<T> = T extends (first: any, secondArg: infer U) => any ? U : never;
export type $anyFixMe = any;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// Lifted from: https://stackoverflow.com/questions/46583883/typescript-pick-properties-with-a-defined-type
export type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};

// From https://stackoverflow.com/questions/40510611/typescript-interface-require-one-of-two-properties-to-exist
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export const unknownClientVersion = '0.0.1';

export function assertExhaustedType(x: never, shouldTrace?: boolean): never {
  const logFn = shouldTrace ? console.trace : console.error;
  logFn(`Violates an exhausted type assertion: ${x}`);
  return undefined as unknown as never;
}

// Safari doesn't support requestIdleCallback, so polyfill it.
export const requestIdleCallback = (callback: () => void) => {
  if ((window as any).requestIdleCallback) return (window as any).requestIdleCallback(callback) as number;
  return window.setTimeout(callback, 0);
};

export const cancelIdleCallback = (handle: number) => {
  if ((window as any).cancelIdleCallback) return (window as any).cancelIdleCallback(handle) as void;
  return window.clearTimeout(handle);
};

export const isPromise = <T>(maybePromise: T | Promise<T>): maybePromise is Promise<T> =>
  maybePromise instanceof Promise;

export const roundToNearest = (value: number, n: number) => Math.round(value / n) * n;

export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export const chop = (
  text: string,
  {
    length = 60,
    keep = 'start',
    terminal = '…',
  }: {
    length?: number;
    keep?: 'start' | 'end';
    terminal?: string;
  } = {},
) => {
  if (text.length <= length) return text;

  if (keep === 'start') {
    return text.substring(0, length) + terminal;
  }
  return terminal + text.substring(text.length - length);
};
